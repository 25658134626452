import React, { Fragment } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { withPage } from "../../Page";
import AppConfig from "../../Config";
import Selectors from "../../Selectors";
import ActionCreator from "../../ActionCreator";
import * as Widget from "../../Components/Widget";
import OrderBasket from "../../Components/OrderBasket";
import OrderStatusBar from "../../Components/OrderStatusBar";
import OrderBuyerInfo from "../../Components/OrderBuyerInfo";
import jsonParser from "../../Utils/JsonParser";

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-self: stretch;

  & > .container {
    flex: 1;
    margin: 0 auto;
    padding-top: 80px;
    padding-bottom: 60px;
    display: flex;
    flex-direction: row;

    @media screen and (max-width: 1200px) {
      flex-direction: column-reverse;
      align-items: center;
      justify-content: flex-start;
    }

    & > .left-section {
      width: 621px;
      margin-right: 100px;
      @media screen and (max-width: 1200px) {
        flex: 1;
        margin-right: 0;
      }
      @media screen and (max-width: 767px) {
        width: 335px;
      }
      @media screen and (max-width: 374px) {
        width: 300px;
      }
    }

    & > .right-section {
      width: 400px;

      @media screen and (max-width: 1200px) {
        width: 620px;
        margin-bottom: 60px;
      }
      @media screen and (max-width: 767px) {
        width: 335px;
      }
      @media screen and (max-width: 374px) {
        width: 300px;
      }
    }
  }

  ${AppConfig["Templates/OrderDetailPage"].css || ""}
`;

const InfoWrapper = styled.div`
  width: 100%;
  height: 100%;

  & > .hint-wrapper {
    margin-top: 30px;
    margin-bottom: 46px;
    padding: 30px;
    border: 1px solid #dadada;
    @media screen and (max-width: 767px) {
      padding: 20px;
    }

    & > p {
      font-size: 14px;
      color: ${AppConfig["global"].themeColor};
    }
  }
`;

class OrderDetailPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showSpinner: false,
      order: null,
      error: null
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    let { appActions, id, profile } = this.props;
    if (profile) {
      appActions
        .getOrder(id)
        .then(order => this.setState({ order }))
        .catch(err => this.setState({ error: err }));
    }
  }

  componentDidUpdate(prevProps) {
    let { appActions, id } = this.props;
    if (!prevProps.profile && this.props.profile) {
      appActions
        .getOrder(id)
        .then(order => this.setState({ order }))
        .catch(err => this.setState({ error: err }));
    }
  }

  render() {
    let { id } = this.props;
    let { order, error } = this.state;

    if (!order) {
      if (error) {
        return <PageWrapper>查無此訂單</PageWrapper>;
      }
      return (
        <PageWrapper>
          <Widget.Center>
            <Widget.Spinner />
          </Widget.Center>
        </PageWrapper>
      );
    }

    return (
      <PageWrapper>
        <div className="container">
          <div className="left-section">
            <OrderStatusBar step={3} />
            <InfoWrapper>
              <div className="hint-wrapper">
                <p>親愛的顧客您好</p>
                <div>&nbsp;</div>
                <p>提醒您，請點選下方付款連結完成付款。</p>
                <p>我們將於款項確認後盡速為您出貨。</p>
                <p>感謝您的訂購</p>
              </div>
              <OrderBuyerInfo order={order} />
            </InfoWrapper>
          </div>
          <div className="right-section">
            <OrderBasket cartData={jsonParser(order.cart, null)} />
          </div>
        </div>
      </PageWrapper>
    );
  }
}

export default withPage(
  connect(
    (state, ownProps) => ({
      profile: Selectors.getLoginUser(state),
      id: (() => {
        let id = Selectors.getQueryParams(ownProps).id;
        return Number.isNaN(Number(id))
          ? id
          : Number(Selectors.getQueryParams(ownProps).id);
      })()
    }),
    ActionCreator
  )(OrderDetailPage)
);
